<template>
  <div>
    <div class="listwork">
      <div class="listwork__top">
        <h4>Danh sách người lao động hợp lệ</h4>
      </div>
      <div class="listwork__table__01">
        <vue-good-table
          :columns="columns"
          :rows="dataInvalid"
          :sort-options="{
            enabled: false,

          }"

          max-height="100%"
          style-class="vgt-table"
          :select-options="{ enabled: false , selectOnCheckboxOnly: true,}"
        >
        </vue-good-table>
      </div>
      <div class="listwork__text__center">
        <p v-if="dataInvalid">
          Tổng cộng: {{ dataInvalid.length }} người lao động
        </p>
      </div>
      <div class="listwork__head__02">
        <div class="listwork__head__02__left">
          <h4>Danh sách người lao động không hợp lệ</h4>
        </div>
        <div class="listwork__head__02__right">

          <b-button
            variant="primary"
            class="btn-icon"
            @click="onOffEdit()"
          >
            <feather-icon icon="EditIcon" />
          </b-button>

          <div class="listwork__head__02__right__icon">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon"
              @click="CheckdataNoInvalid()"
            >
              <feather-icon icon="CheckCircleIcon" />
            </b-button>
          </div>
          <div />
        </div>

      </div>
      <div class="listwork__table__02">
        <vue-good-table
          :fixed-header="true"
          :columns="columns02"
          :rows="dataNoInvalid"
          :select-options="{ enabled: false , selectOnCheckboxOnly: true,}"
          style-class="vgt-table"
          :sort-options="{
            enabled: false,

          }"
        >

          <template
            slot="table-row"
            slot-scope="props"
          >
            <!--======================= Thêm icon lỗi =================================== -->
            <span
              v-if="props.column.field == 'icon02'"
              v-b-tooltip.hover.right.html="props.row.messErr[0].message"
            >
              <feather-icon
                icon="AlertTriangleIcon"
                color="red"
              />
            </span>
            <!--======================= Color cho vị trí bị lỗi =================================== -->
            <!-- code- -->
            <div
              v-if="props.column.field == 'code' && editvalueErr === false"
              class="style-table"
              :class="{text_danger:props.row.messErr[0].location === 'Code'||!props.row.code,dataErr:!props.row.code,}"
            >

              {{ props.row.code }}
            </div>

            <div
              v-if="props.column.field == 'code' && editvalueErr === true "
              class="style-table"
            >
              <input
                v-if="props.row.messErr[0].location === 'Code'"
                v-model="props.row.code"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.code, 'code', props.row)"
              >
              <div
                v-if="props.row.messErr[0].location !== 'Code'"
                class="style-table"
              >{{ props.row.code }}</div>
            </div>
            <!-- name- -->
            <div
              v-if="props.column.field == 'name' && editvalueErr === false"
              class="style-table"
              :class="{text_danger:props.row.messErr[0].location === 'Name'||!props.row.name,dataErr:!props.row.name,}"
            >

              {{ props.row.name }}
            </div>

            <div
              v-if="props.column.field == 'name' && editvalueErr === true "
              class="style-table"
            >
              <input
                v-if="props.row.messErr[0].location === 'Name'"
                v-model="props.row.name"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.name, 'name', props.row)"
              >
              <div
                v-if="props.row.messErr[0].location !== 'Name'"
                class="style-table"
              >{{ props.row.name }}</div>
            </div>
            <!-- birthDay-- -->
            <div
              v-if="props.column.field == 'birthDay' && editvalueErr === false"
              class="style-table"
              :class="{text_danger:props.row.messErr[0].location === 'BirthDay'||!props.row.birthDay,dataErr:!props.row.birthDay,}"
            >

              {{ props.row.birthDay }}
            </div>

            <div
              v-if="props.column.field == 'birthDay' && editvalueErr === true "
              class="style-table"
            >
              <input
                v-if="props.row.messErr[0].location === 'BirthDay'"
                v-model="props.row.birthDay"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.birthDay, 'birthDay', props.row)"
              >
              <div
                v-if="props.row.messErr[0].location !== 'BirthDay'"
              >{{ props.row.birthDay }}</div>
            </div>
            <!-- gender-- -->
            <div
              v-if="props.column.field == 'gender' && editvalueErr === false"
              class="style-table"
              :class="{text_danger:props.row.messErr[0].location === 'Gender'||!props.row.gender,dataErr:!props.row.gender,}"
            >

              {{ props.row.gender }}
            </div>

            <div
              v-if="props.column.field == 'gender' && editvalueErr === true "
              class="style-table"
            >
              <input
                v-if="props.row.messErr[0].location === 'Gender'"
                v-model="props.row.gender"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.gender, 'gender', props.row)"
              >
              <div
                v-if="props.row.messErr[0].location !== 'Gender'"
                class="style-table"
              >{{ props.row.gender }}</div>
            </div>
            <!-- identityCardNumber số cmnd -->
            <div
              v-if="props.column.field == 'identityCardNumber' && editvalueErr === false"
              class="style-table"
              :class="{text_danger:props.row.messErr[0].location === 'IdentityCardNumber'||!props.row.identityCardNumber,dataErr:!props.row.identityCardNumber,}"
            >

              {{ props.row.identityCardNumber }}
            </div>

            <div
              v-if="props.column.field == 'identityCardNumber' && editvalueErr === true "
              class="style-table"
            >
              <input
                v-if="props.row.messErr[0].location === 'IdentityCardNumber'"
                v-model="props.row.identityCardNumber"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.identityCardNumber, 'identityCardNumber', props.row)"
              >
              <div
                v-if="props.row.messErr[0].location !== 'IdentityCardNumber'"
                class="style-table"
              >{{ props.row.identityCardNumber }}</div>
            </div>
            <!-- country-quốc tịch -->
            <div
              v-if="props.column.field == 'country' && editvalueErr === false"
              class="style-table"
              :class="{text_danger:props.row.messErr[0].location === 'Country'||!props.row.country,dataErr:!props.row.country,}"
            >

              {{ props.row.country }}
            </div>
            <div
              v-if="props.column.field == 'country' && editvalueErr === true "
              class="style-table"
            >

              <v-select
                v-if="props.row.messErr[0].location === 'Country'"
                v-model="props.row.country"
                :reduce="title => title.name"
                label="name"
                :options="arrContry"
                :placeholder="'Lựa chọn quốc tịch'"
                @input="changeCellvalue(props.row.country, 'country', props.row)"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>

              <div
                v-if="props.row.messErr[0].location !== 'Country'"
                class="style-table"
              >{{ props.row.country }}</div>
            </div>
            <!-- Địa chỉ : address -->
            <div
              v-if="props.column.field == 'address' && editvalueErr === false"
              class="style-table"
              :class="{text_danger:props.row.messErr[0].location === 'Address'||!props.row.address,dataErr:!props.row.address,}"
            >

              {{ props.row.address }}
            </div>

            <div
              v-if="props.column.field == 'address' && editvalueErr === true "
              class="style-table"
            >
              <input
                v-if="props.row.messErr[0].location === 'Address'"
                v-model="props.row.address"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.address, 'address', props.row)"
              >
              <div
                v-if="props.row.messErr[0].location !== 'Address'"
                class="style-table"
              >{{ props.row.address }}</div>
            </div>
            <!--  email -->
            <div
              v-if="props.column.field == 'email' && editvalueErr === false"
              class="style-table"
              :class="{text_danger:props.row.messErr[0].location === 'Email'||!props.row.email,dataErr:!props.row.email,}"
            >

              {{ props.row.email }}
            </div>

            <div
              v-if="props.column.field == 'email' && editvalueErr === true "
              class="style-table"
            >
              <input
                v-if="props.row.messErr[0].location === 'Email'"
                v-model="props.row.email"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.email, 'email', props.row)"
              >
              <div
                v-if="props.row.messErr[0].location !== 'Email'"
                class="style-table"
              >{{ props.row.email }}</div>
            </div>
            <!--  phoneNumber -->
            <div
              v-if="props.column.field == 'phoneNumber' && editvalueErr === false"
              class="style-table"
              :class="{text_danger:props.row.messErr[0].location === 'PhoneNumber'||!props.row.phoneNumber,dataErr:!props.row.phoneNumber,}"
            >

              {{ props.row.phoneNumber }}
            </div>

            <div
              v-if="props.column.field == 'phoneNumber' && editvalueErr === true "
              class="style-table"
            >
              <input
                v-if="props.row.messErr[0].location === 'PhoneNumber'"
                v-model="props.row.phoneNumber"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.phoneNumber, 'phoneNumber', props.row)"
              >
              <div
                v-if="props.row.messErr[0].location !== 'PhoneNumber'"
                class="style-table"
              >{{ props.row.phoneNumber }}</div>
            </div>
            <!-- -----------end -->
            <!-- Trình độ học vấn : academicLevel -->
            <div
              v-if="props.column.field == 'academicLevel' && editvalueErr === false"
              class="style-table"
              :class="{text_danger:props.row.messErr[0].location === 'AcademicLevel'||!props.row.academicLevel,dataErr:!props.row.academicLevel,}"
            >

              {{ props.row.academicLevel }}
            </div>
            <div
              v-if="props.column.field == 'academicLevel' && editvalueErr === true "
              class="style-table"
            >

              <v-select
                v-if="props.row.messErr[0].location === 'AcademicLevel'"
                v-model="props.row.academicLevel"
                :reduce="title => title.label"
                label="label"
                :options="academicLevel"
                :placeholder="'Lựa chọn loại hình doanh nghiệp'"
                @input="changeCellvalue(props.row.academicLevel, 'academicLevel', props.row)"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>

              <div
                v-if="props.row.messErr[0].location !== 'AcademicLevel'"
                class="style-table"
              >{{ props.row.academicLevel }}</div>
            </div>
            <!-- Vị trí công việc : position -->
            <div
              v-if="props.column.field == 'position' && editvalueErr === false"
              class="style-table"
              :class="{text_danger:props.row.messErr[0].location === 'Position'||!props.row.position,dataErr:!props.row.position,}"
            >

              {{ props.row.position }}
            </div>
            <div
              v-if="props.column.field == 'position' && editvalueErr === true "
              class="style-table"
            >

              <v-select
                v-if="props.row.messErr[0].location === 'Position'"
                v-model="props.row.position"
                :reduce="title => title.name"
                label="name"
                :options="arrPossition"
                :placeholder="'Lựa chọn vị trí công việc'"
                @input="changeCellvalue(props.row.position, 'position', props.row)"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>

              <div
                v-if="props.row.messErr[0].location !== 'Position'"
                class="style-table"
              >{{ props.row.position }}</div>
            </div>

            <!-- Danh mục nghề nghiệp : typeOfProfession -->
            <div
              v-if="props.column.field == 'typeOfProfession' && editvalueErr === false"
              class="style-table"
              :class="{text_danger:props.row.messErr[0].location === 'TypeOfProfession'||!props.row.typeOfProfession,dataErr:!props.row.typeOfProfession,}"
            >

              {{ props.row.typeOfProfession }}
            </div>
            <div
              v-if="props.column.field == 'typeOfProfession' && editvalueErr === true "
              class="style-table"
            >
              <v-select
                v-if="props.row.messErr[0].location === 'TypeOfProfession'"
                v-model="props.row.typeOfProfession"
                :reduce="title => title.name"
                label="name"
                :options="dataCombobox || []"
                :placeholder="'Lựa chọn danh mục nghề nghiệp'"
                @input="changeCellvalue(props.row.typeOfProfession, 'typeOfProfession', props.row)"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <div
                v-if="props.row.messErr[0].location !== 'TypeOfProfession'"
                class="style-table"
              >{{ props.row.typeOfProfession }}</div>
            </div>

            <!-- Loại hợp đồng lao động : contractType -->
            <div
              v-if="props.column.field == 'contractType' && editvalueErr === false"
              class="style-table"
              :class="{text_danger:props.row.messErr[0].location === 'ContractType'||!props.row.contractType,dataErr:!props.row.contractType,}"
            >

              {{ props.row.contractType }}
            </div>
            <div
              v-if="props.column.field == 'contractType' && editvalueErr === true "
              class="style-table"
            >
              <v-select
                v-if="props.row.messErr[0].location === 'ContractType'"
                v-model="props.row.contractType"
                :reduce="title => title"
                :options="contractType || []"
                :placeholder="'Lựa chọn danh mục nghề nghiệp'"
                @input="changeCellvalue(props.row.contractType, 'contractType', props.row)"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <div
                v-if="props.row.messErr[0].location !== 'ContractType'"
                class="style-table"
              >{{ props.row.contractType }}</div>
            </div>
            <!-- ------------------------ô select------- -->
          </template>
        </vue-good-table>
      </div>
      <div class="listwork__text__center">
        <p v-if="dataNoInvalid">
          Tổng cộng: {{ dataNoInvalid.length }} người lao động
        </p>
      </div>

      <div class="listwork__button">
        <div class="listwork__button__item">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="addPositionJob()"
          >
            Thêm người lao động
          </b-button>
        </div>
        <div class="listwork__button__item">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="close()"
          >
            Hủy bỏ
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton, VBTooltip } from 'bootstrap-vue'
import { mapState, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import VSelect from 'vue-select'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import OccupationSelect from '@/views/management-workers/occupation-list/pages/components/OccupationSelect.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    VueGoodTable,
    BButton,
    VSelect,
    VBTooltip,
    OccupationSelect,

  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      academicLevel: [ // Lọc theo trình độ học vấn
        {
          label: 'Đại học',
          value: 'University',
        },
        {
          label: 'Cao đẳng',
          value: 'College',
        },
        {
          label: 'Trung cấp',
          value: 'Intermediate',
        },
        {
          label: 'Sơ cấp nghề',
          value: 'Primary',
        },
        {
          label: 'Chưa qua đào tạo',
          value: 'None',
        },
      ],
      dataInvalid: [],
      dataNoInvalid: [],
      editvalueErr: false, // bật tắt chỉnh sửa
      columns: [
        {
          label: 'Mã người lao động',
          field: 'code',
          width: '200px',
        },
        {
          label: 'Tên người lao động',
          field: 'name',
          width: '200px',
        },
        {
          label: 'Ngày sinh',
          field: 'birthDay',
          width: '200px',
        },
        {
          label: 'Giới tính',
          field: 'gender',
          width: '200px',
        },
        {
          label: 'Số CMND/CCCD/HC',
          field: 'identityCardNumber',
          width: '200px',
        },
        {
          label: 'Quốc tịch',
          field: 'country',
          width: '200px',
        },
        {
          label: 'Địa chỉ',
          field: 'address',
          width: '200px',
        },
        {
          label: 'Email ',
          field: 'email',
          width: '200px',
        },
        {
          label: 'Số điện thoại',
          field: 'phoneNumber',
          width: '200px',
        },
        {
          label: 'Trình độ học vấn',
          field: 'academicLevel',
          width: '200px',
        },
        {
          label: 'Vị trí công việc',
          field: 'position',
          width: '200px',
        },
        {
          label: 'Loại hợp đồng lao động',
          field: 'contractType',
          width: '200px',
        },
        {
          label: 'Danh mục nghề nghiệp',
          field: 'typeOfProfession',
          width: '200px',
        },
      ],
      columns02: [
        {
          label: '',
          field: 'icon02',
          tdClass: 'style-tablue',
          thClass: 'style-tablue',
        },
        {
          label: 'Mã người lao động',
          field: 'code',
          width: '200px',
          tdClass: 'style-tablue',
          thClass: 'style-tablue',
        },
        {
          label: 'Tên người lao động',
          field: 'name',
          width: '200px',
        },
        {
          label: 'Ngày sinh',
          field: 'birthDay',
          width: '200px',
        },
        {
          label: 'Giới tính',
          field: 'gender',
          width: '200px',
        },
        {
          label: 'Số CMND/CCCD/HC',
          field: 'identityCardNumber',
          width: '200px',
        },
        {
          label: 'Quốc tịch',
          field: 'country',
          width: '200px',
        },
        {
          label: 'Địa chỉ',
          field: 'address',
          width: '200px',
        },
        {
          label: 'Email ',
          field: 'email',
          width: '200px',
        },
        {
          label: 'Số điện thoại',
          field: 'phoneNumber',
          width: '200px',
        },
        {
          label: 'Trình độ học vấn',
          field: 'academicLevel',
          width: '200px',
        },
        {
          label: 'Vị trí công việc',
          field: 'position',
          width: '200px',
        },
        {
          label: 'Loại hợp đồng lao động',
          field: 'contractType',
          width: '200px',
        },
        {
          label: 'Danh mục nghề nghiệp',
          field: 'typeOfProfession',
          width: '200px',
        },
      ],
      dataCheck: [],
      selectbussinessType: '',
      arrContry: [],
      arrPossition: [],
      contractType: [
        'Hợp đồng lao động vô thời hạn', 'Hợp đồng lao động xác định thời hạn', 'Hợp đồng lao động khác', 'Hợp đồng lao động dưới 2 năm',
      ],
    }
  },
  computed: {
    ...mapState('worker', ['dataVaild', 'dataNoValid', 'dataCombobox']),
  },

  mounted() {
    // tdClass: 'style-code123',
    this.dataInvalid = this.dataVaild
    this.dataNoInvalid = this.dataNoValid
    this.fetchdataTypeOfProfession()
  },
  // eslint-disable-next-line no-empty-function
  async created() {
    this.getAllComboBox()
    const fetchContry = await this.fecthCountry()
    this.arrContry = fetchContry.data
  },
  methods: {
    ...mapActions('worker', ['getApiExcel', 'fecthCountry', 'fetchdataTypeOfProfession']),

    // eslint-disable-next-line consistent-return
    async getAllComboBox() {
      const { data } = await axiosApiInstance.get('/Worker/get-combobox-position-worker', {
        params: { countryId: -1 },
      })
      this.arrPossition = data?.data
    },

    // async getComboBox(countryId) {
    //   const { data } = await axiosApiInstance.get('/Worker/get-combobox-contract-type', {
    //     params: { countryId },
    //   })
    //   this.contractType = data?.data
    // },

    valueSelect01234(e) {
      this.valueSelect123 = e
    },
    // BẬT tắt chỉnh sửa
    onOffEdit() {
      this.editvalueErr = !this.editvalueErr
    },
    // thay đổi dữ liệu trên bảng
    changeCellvalue(event, field, rowData) {
      this.dataNoInvalid[rowData.originalIndex][field] = event
    },
    // nút check
    async CheckdataNoInvalid() {
      if (this.dataInvalid && this.dataInvalid.length > 0) {
        const model = {
          listExcel: this.dataInvalid.concat(this.dataNoInvalid),
          isValidate: true,
        }
        const valuedata = await this.getApiExcel(model)
        this.dataInvalid = valuedata.data.filter(x => x.isSuccess === true)
        this.dataNoInvalid = valuedata.data.filter(x => x.isSuccess === false)
      } else {
        const model = {
          listExcel: this.dataNoInvalid,
          isValidate: true,
        }
        this.$showAllPageLoading()
        const valuedata = await this.getApiExcel(model)
        this.dataInvalid = valuedata.data.filter(x => x.isSuccess === true)
        this.dataNoInvalid = valuedata.data.filter(x => x.isSuccess === false)
        this.$hideAllPageLoading()
      }
    },
    // nút hủy bỏ
    close() {
      this.$router.push({ name: 'manage-workers' })
    },
    // nút thêm
    // eslint-disable-next-line consistent-return
    async addPositionJob() {
      if (this.dataInvalid && this.dataInvalid.length !== 0) {
        const model = {
          listExcel: this.dataInvalid,
          isValidate: false,
        }
        this.$showAllPageLoading()
        const res = await this.getApiExcel(model)
        if (res.oke) {
          this.dataInvalid = []
          if (this.dataNoInvalid && this.dataNoInvalid.length === 0) {
            this.$router.push({ name: 'manage-workers' })
          }
          this.$hideAllPageLoading()
          this.showToast('success', 'Thêm thành công!!!')
        } else {
          this.$hideAllPageLoading()
          this.showToast('danger', 'Thêm thất bại!')
        }
      } else {
        await this.showToast('danger', 'Không có dữ liệu để thêm!!!')
      }
    },
    showToast(variant, nameAlert) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Thông báo',
          icon: 'BellIcon',
          text: nameAlert,
          variant,
        },
      })
    },
    classErr() {

    },
    selectClassify(e, b, c, d) {
      b = e
      this.changeCellvalue(b, c, d)
    },

  },
}
</script>

<style>
.listwork{
background: white;
box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
border-radius: 6px;

}

.listwork__top{
padding:20px;}
.listwork__top h4{
font-family: Montserrat;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;

color: #181F28
}
.listwork__text__center p{
font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 21px;
text-align: right;
margin-top:20px;
padding-right:20px;

}
.listwork__head__02{
display: flex;
align-items: center;
justify-content: space-between;
padding:20px;
}
.listwork__head__02__left h4{
font-family: Montserrat;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;
color: #000000;
}
.listwork__head__02__right{
display: flex;
align-items: center;}
.listwork__head__02__right__icon{
margin-left: 20px;}

.listwork__button{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top:40px;
  padding-right: 20px;
  padding-bottom: 20px;
}
.listwork__button__item{
  margin-left:20px
}
.table-listwork{
    width: 100%;
}
.table-listwork thead tr th{
  background-color: rgba(34, 127, 244, 0.12);
padding-left:10px ;
}

.table-listwork thead tr{
  height: 39px;
  background-color: transparent;
}
.table-listwork  tbody tr{
  height: 58px;
  border-bottom: 1px solid #CBCDD2;

}
.table-listwork  tbody tr th{
 padding-left:10px ;
}
.table-listwork  tbody tr td{
 padding-left:10px ;
}
.text_danger{
  color:red
}
.cssInput{
  outline: none;
  border: 0px;
  border-bottom: 0.2px solid gray;
}
.style-code123{
  padding-right: 20px ;
}
.dataErr::before{
  content: 'Không được để trống';
  color:red,
}
.style-table{
  width: 200px;
}
</style>
